// ** React Imports
import { Fragment, useContext, useEffect, useState } from 'react'

// ** Reactstrap Imports
import { Card, CardBody, CardTitle, Input, Label, Button, Badge, CustomInput, Row, Col } from 'reactstrap'

// ** Icons Imports
import { Check, X, Link, Calendar, ExternalLink } from 'react-feather'
import { useSelector } from 'react-redux'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import { Icon } from '@iconify/react'
// import CampaignInfoCard from "./CampaignInfoCard"
import LinearProgress from "@mui/material/LinearProgress"
import { _fanFinderGetSubscriptionAdmin, _fanFinderArtistRelatedFansAdmin, _fanFinderArtistFansAdmin, _fanFinderCheckSubscriptionAdmin } from '../../redux/actions'
import FanFinderPlansFeatures from '../../../../components/FanFinderPlansFeatures'
import moment from 'moment'
import { TruncatedTextComponent } from '../../../../components/TruncatedTextComponent'
import FansCardStats from './FansCardStats'
import { ThemeColors } from '@src/utility/context/ThemeColors'
const Subscriptions = (props) => {
  const [subscription, setSubscription] = useState(null)
  const loadings = useSelector(state => state.app.loading)
  const [artistLoading, setArtistLoading] = useState(true)
  const [fansLoading, setFansLoading] = useState(true)
  const [fansSimilarLoading, setFansSimilarLoading] = useState(true)
  const [artistFans, setArtistFans] = useState([])
  const [relatedArtistFans, setRelatedArtistFans] = useState([])
  const [artistFansLoading, setArtistFansLoading] = useState(true)
  const [relatedFansLoading, setRelatedFansLoading] = useState(true)
  const [checkSubscribe, setCheckSubscribe] = useState(null)

  const getSubscriptionArtist = async () => {
    try {
      setArtistLoading(true)
      setArtistFansLoading(true)
      setRelatedFansLoading(true)

      // First API call
      _fanFinderCheckSubscriptionAdmin(props?.user?.id, (checkResult) => {
        setCheckSubscribe(checkResult)

        // Second API call
        _fanFinderGetSubscriptionAdmin(props?.user?.id, (subscribeData) => {
          setSubscription(subscribeData)

          // Only make fan-related API calls if subscription is active
          if (checkResult?.is_subscribed && !checkResult?.is_expired &&
            checkResult?.is_active && subscribeData?.instagram_username) {

            // Third API call - Artist Fans
            _fanFinderArtistFansAdmin({
              by_id: false,
              id: props?.user?.id,
              identifier: subscribeData.instagram_username
            }, (fansData) => {
              setArtistFans(fansData || [])
              setArtistFansLoading(false)
            })

            // Fourth API call - Related Artist Fans  
            _fanFinderArtistRelatedFansAdmin({
              by_id: false,
              id: props?.user?.id,
              identifier: subscribeData.instagram_username
            }, (relatedFans) => {
              setRelatedArtistFans(relatedFans || [])
              setRelatedFansLoading(false)
            })
          } else {
            setArtistFans([])
            setRelatedArtistFans([])
            setArtistFansLoading(false)
            setRelatedFansLoading(false)
          }
        })
        setArtistLoading(false)
      })
    } catch (error) {
      console.error('Error fetching subscription data:', error)
      setArtistLoading(false)
      setArtistFansLoading(false)
      setRelatedFansLoading(false)
    }
  }
  const { colors } = useContext(ThemeColors),
    trackBgColor = '#e9ecef'
  useEffect(() => {
    getSubscriptionArtist()

  }, [])
  return (
    <Fragment>
      <Card style={{ minHeight: '300px' }}>
        <CardBody className="d-flex flex-column">
          {loadings ? (
            <ShimmerThumbnail width={'100%'} height={200} rounded className='dark-shimmer-thumbnail' />
          ) : !subscription ? (
            <div className="text-center">
              <div className="mb-2">
                <Icon
                  icon="mdi:playlist-music"
                  width={60}
                  height={60}
                  style={{ opacity: 0.5 }}
                />
              </div>
              <h4 className="inter-bold-font mb-1">No Active Subscription</h4>
            </div>
          ) : (
            <>
              {/* <h4 className="mb-2">Plan Details</h4> */}
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h4 className="mb-0">{subscription?.plan?.plan_name}</h4>
                <Badge color={subscription?.status ? 'primary' : 'danger'} pill>
                  {subscription?.status ? 'Active' : 'Inactive'}
                </Badge>
              </div>
              <div className="subscription-info mb-2">
                <div className='d-flex align-items-center mb-1'>
                  {/* Start Date */}
                  <div className='d-flex align-items-center mr-4'>
                    <div className='color-box bg-light-primary' style={{ padding: '0.7rem' }}>
                      <Calendar className='text-primary' size={22} />
                    </div>
                    <div className='ml-1'>
                      <h6 className='mb-0'>
                        <p style={{ fontSize: '0.8rem', marginBottom: '0.5rem' }}>Start Date</p>
                        <span style={{ fontSize: '0.85rem' }}>
                          {moment(subscription?.start_data).format('Y-MM-DD')}
                        </span>
                      </h6>
                    </div>
                  </div>

                  {/* End Date */}
                  <div className='d-flex align-items-center mr-4'>
                    <div className='color-box bg-light-primary' style={{ padding: '0.7rem' }}>
                      <Calendar className='text-primary' size={22} />
                    </div>
                    <div className='ml-1'>
                      <h6 className='mb-0'>
                        <p style={{ fontSize: '0.8rem', marginBottom: '0.5rem' }}>End Date</p>
                        <span style={{ fontSize: '0.85rem' }}>
                          {moment(subscription?.end_data).format('Y-MM-DD')}
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
                {subscription?.status ? <div className='mb-1 p-1' style={{ background: 'rgba(255,255,255,0.05)', borderRadius: '8px' }}>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div>
                      <h4 className='mb-0'>Auto-Renew Subscription</h4>
                      <small className='text-muted'>Automatically renew your subscription when it ends</small>
                    </div>
                    <CustomInput
                      type="switch"
                      id="autoRenewSwitch"
                      name="autoRenewSwitch"
                      checked={subscription?.auto_renew}
                    // onChange={handleAutoRenewToggle}
                    // disabled={isUpdatingAutoRenew}
                    />
                  </div>

                </div> : null}

              </div>

              {/* Add Remaining Days Section */}
              <div className="remaining-days mt-auto">
                {subscription?.status && (
                  <>
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <small className="text-muted">Subscription Duration</small>
                      <small className="inter-bold-font">{subscription?.remaining_days} of {subscription?.plan?.plan_days} Days</small>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={(subscription?.remaining_days / subscription?.plan?.plan_days) * 100}
                      //className="progress-bar-custom"
                      sx={{
                        borderRadius: 3,
                        height: 6,
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: '#5ECBC8' // Spotify green color
                        }
                      }}
                    />
                    {/* <small className="text-muted mb-2">{(Number(subscription?.plan?.plan_days) - Number(subscription?.remaining_days))} Remaining Days</small> */}
                  </>
                )}
              </div>
            </>
          )}
        </CardBody>
      </Card>
      <Row>
        <Col sm='12' lg={6} md={6}>
          {subscription ? <Card className="mt-2" style={{ minHeight: '230px' }}>
            <CardBody>
              <h4 className="mb-2">Connected Accounts</h4>

              {/* Spotify Account */}
              <div className='d-flex align-items-center justify-content-between mb-2'>
                <div className='d-flex align-items-center me-2'>
                  <Icon icon="iconoir:spotify" width={35} height={35} color='white' />
                  <div className='ms-75 pl-1'>
                    <h5 className='mb-0' style={{ fontSize: '14px', fontWeight: 'normal' }}>Spotify</h5>
                    <small className='inter-bold-font'>{subscription?.spotify_name}</small>
                  </div>
                </div>
                <small>
                  <a
                    href={`https://open.spotify.com/artist/${subscription?.spotify_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary d-flex align-items-center"
                    style={{ textDecoration: 'none' }}
                  >
                    View on Spotify <ExternalLink size={14} className="ml-1" />
                  </a>
                </small>
              </div>

              {/* Instagram Account */}
              <div className='d-flex align-items-center justify-content-between mb-2'>
                <div className='d-flex align-items-center me-2'>
                  <Icon icon="bi:instagram" width={30} height={30} color='white' />
                  <div className='ms-75 pl-1'>
                    <h5 className='mb-0' style={{ fontSize: '14px', fontWeight: 'normal' }}>Instagram</h5>
                    <small className='inter-bold-font'>@{subscription?.instagram_username}</small>
                  </div>
                </div>
                <small>
                  <a
                    href={`https://instagram.com/${subscription?.instagram_username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary d-flex align-items-center"
                    style={{ textDecoration: 'none' }}
                  >
                    View on Instagram <ExternalLink size={14} className="ml-1" />
                  </a>
                </small>
              </div>
            </CardBody>
          </Card> : null}
        </Col>
        <Col sm='12' lg={6} md={6}>
          {subscription?.related_artists?.length > 0 && (
            <Card className="mt-2">
              <CardBody>
                <h4 className="mb-2">Related Artists</h4>
                {subscription.related_artists.map((artist, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center mb-1 p-1"
                    style={{
                      background: 'rgba(255,255,255,0.05)',
                      borderRadius: '8px'
                    }}
                  >
                    <img
                      src={artist.spotify_image}
                      alt={artist.spotify_name}
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        marginRight: '10px'
                      }}
                    />
                    <h3 style={{ margin: 0, fontSize: '15px' }} className='inter-bold-font'>
                      <TruncatedTextComponent
                        id={`artist-name-${index}`}
                        text={artist.spotify_name}
                      />
                    </h3>
                    <div className="ml-auto d-flex align-items-center">
                      {artist.spotify_url && (
                        <a href={artist.spotify_url} target="_blank" rel="noopener noreferrer" className="mr-1">
                          <Icon icon="iconoir:spotify" style={{ color: 'white', fontSize: '20px' }} />
                        </a>
                      )}
                      {artist.instagram_username && (
                        <a href={`https://instagram.com/${artist.instagram_username}`} target="_blank" rel="noopener noreferrer">
                          <Icon icon="mdi:instagram" style={{ color: 'white', fontSize: '20px' }} />
                        </a>
                      )}
                    </div>
                  </div>
                ))}
              </CardBody>
            </Card>
          )}
        </Col>
        <Col xs={12} xl="12" md="12">
          <Card className="mb-2" style={{ minHeight: '300px' }}>
            <CardBody>
              {artistFansLoading ? (
                <div className="fan-grid-shimmer" style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
                  gap: '1rem',
                  alignItems: 'center'
                }}>
                  {[...Array(4)].map((_, i) => (
                    <ShimmerThumbnail
                      key={i}
                      width="100%"
                      height={220}
                      rounded
                      className='dark-shimmer-thumbnail'
                    />
                  ))}
                </div>
              ) : !subscription?.status || checkSubscribe?.is_expired ? (
                <div className="text-center p-4">
                  <Icon
                    icon="mdi:lock-outline"
                    width={50}
                    height={50}
                    style={{ opacity: 0.5 }}
                  />
                  <h4 className="mt-2">Not Available!</h4>

                </div>
              ) : (
                <FansCardStats
                  colors={colors}
                  trackBgColor={trackBgColor}
                  type={1}
                  fans={artistFans}
                  connected={1}
                />
              )}
            </CardBody>
          </Card>

          <Card style={{ minHeight: '300px' }}>
            <CardBody>
              {relatedFansLoading ? (
                <div className="fan-grid-shimmer" style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
                  gap: '1rem',
                  alignItems: 'center'
                }}>
                  {[...Array(4)].map((_, i) => (
                    <ShimmerThumbnail
                      key={i}
                      width="100%"
                      height={220}
                      rounded
                      className='dark-shimmer-thumbnail'
                    />
                  ))}
                </div>
              ) : !subscription?.status || checkSubscribe?.is_expired ? (
                <div className="text-center p-4">
                  <Icon
                    icon="mdi:lock-outline"
                    width={50}
                    height={50}
                    style={{ opacity: 0.5 }}
                  />
                  <h4 className="mt-2">Not Available!</h4>
                </div>
              ) : (
                subscription?.related_artists?.length > 0 ? <FansCardStats
                  colors={colors}
                  trackBgColor={trackBgColor}
                  type={2}
                  fans={relatedArtistFans}
                  connected={1}
                /> : null
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default Subscriptions
