import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

const FanFinderPlansFeatures = ({ dotColor = '#5ECBC8', textColor = 'rgba(255, 255, 255, 0.7)' }) => {
  const features = [
    'Initial 6 posts analysis',
    'Weekly posts analysis updates',
    'Using Advanced AI algorithm',
    'Up to 50 Comments per Post',
    'Weekly Email notifications',
    'Fans Engagement Level',
    '2 Related Artist Fans analysis',
    'Early Access to new Features'
  ]

  return (
    <List sx={{ width: '100%', mt: 2 }}>
      {features.map((feature, index) => (
        <ListItem 
          key={index}
          sx={{
            py: 0.3, // Reduced from 0.8 to decrease spacing
            px: 0
          }}
        >
          <ListItemIcon sx={{ minWidth: 25 }}>
            <FiberManualRecordIcon 
              sx={{ 
                fontSize: 10, // Increased dot size
                color: dotColor
              }} 
            />
          </ListItemIcon>
          <ListItemText 
            primary={feature}
            sx={{
              '& .MuiListItemText-primary': {
                fontSize: '1rem', // Increased from 0.875rem
                color: textColor,
                fontWeight: 400
              }
            }}
          />
        </ListItem>
      ))}
    </List>
  )
}

export default FanFinderPlansFeatures