// ** React Imports
import { Fragment, useState } from 'react'

// ** Reactstrap Imports
// import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

// ** Icons Imports
import { User, Lock, Bookmark, Bell, Link, Home, Briefcase, File, FileText } from 'react-feather'

// ** User Components
import UserProjectsList from './UserProjectsList'
import DashboardTab from "./DashboardTab"
import CampaignsTab from "./CampaignsTab"
import InvoicesPayoutsTab from "./InvoicesPayoutsTab"
import ExpressCuratorPlaylists from "./ExpressCuratorPlaylists"
import UserConnectedAccounts from "./UserConnectedAccounts"
import TicketsTab from "./TicketsTab"
import Nav from 'react-bootstrap/Nav'
// import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import FormList from "./FormList"
import { useParams } from "react-router-dom"
import FormsTab from "./FormsTab"
import UserActivities from './UserActivities'
import Subscriptions from './Subscriptions'

const UserTabs = ({ user, userId, status, sum }) => {
  const [active, setActive] = useState('1')
  //const { userId } = useParams()


  return (
    <Tab.Container defaultActiveKey="1">
      <Nav variant="pills" className='mb-2' >
        <Nav.Item>
          <Nav.Link eventKey={'1'}>
            <Home size={17} />
            <span className='fw-bold'>Dashboard</span>
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link eventKey={'2'}>
            <Briefcase size={17} />
            <span className='fw-bold'>Campaigns</span>
          </Nav.Link>
        </Nav.Item>
        {user.user_type_id === 2 ? <Nav.Item>
          <Nav.Link eventKey={'6'}>
            <FileText size={17} />
            <span className='fw-bold'>Express Playlists</span>
          </Nav.Link>
        </Nav.Item> : null}
        <Nav.Item>
          <Nav.Link eventKey={'3'}>
            <FileText size={17} />
            <span className='fw-bold'>{user.user_type_id === 1 ? 'Wallet' : 'Wallet'}</span>
          </Nav.Link>
        </Nav.Item>
        {/* {user.user_type_id === 1 ? (
        <Nav.Item>
          <Nav.Link eventKey={'4'}>
            <File size={17}/>
            <span className='fw-bold'>Forms</span>
          </Nav.Link>
        </Nav.Item>
        ) : ''} */}
        <Nav.Item>
          <Nav.Link eventKey={'5'}>
            <FileText size={17} />
            <span className='fw-bold'>Tickets</span>
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link eventKey={'8'}>
            <Link size={17} />
            <span className='fw-bold'>Connections</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={'10'}>
            <Link size={17} />
            <span className='fw-bold'>Activities</span>
          </Nav.Link>
        </Nav.Item>
        {user.user_type_id === 1 ? <Nav.Item>
          <Nav.Link eventKey={'11'}>
            <FileText size={17} />
            <span className='fw-bold'>Fan Finder Subscription</span>
          </Nav.Link>
        </Nav.Item> : null}
      </Nav>
      <Tab.Content activeTab={active}>
        <Tab.Pane eventKey={'1'} mountOnEnter={true} unmountOnExit={true}>
          <DashboardTab user={user} />
        </Tab.Pane>
        <Tab.Pane eventKey={'2'} mountOnEnter={true} unmountOnExit={true}>
          <CampaignsTab user={user} />
        </Tab.Pane>
        <Tab.Pane eventKey={'4'} mountOnEnter={true} unmountOnExit={true}>
          <InvoicesPayoutsTab user={user} />
        </Tab.Pane>
        <Tab.Pane eventKey={'3'} mountOnEnter={true} unmountOnExit={true}>
          <InvoicesPayoutsTab user={user} sum={sum} />
        </Tab.Pane>
        <Tab.Pane eventKey={'6'} mountOnEnter={true} unmountOnExit={true}>
          <ExpressCuratorPlaylists curator={user} />
        </Tab.Pane>
        {/* <Tab.Pane eventKey={'4'} mountOnEnter={true} unmountOnExit={true}>
          <FormsTab user={user} status={status} />
        </Tab.Pane> */}
        <Tab.Pane eventKey={'5'} mountOnEnter={true} unmountOnExit={true}>
          <TicketsTab user={user} status={status} userId={userId} />
        </Tab.Pane>
        <Tab.Pane eventKey={'8'} mountOnEnter={true} unmountOnExit={true}>
          <UserConnectedAccounts user={user} />
        </Tab.Pane>
        <Tab.Pane eventKey={'10'} mountOnEnter={true} unmountOnExit={true}>
          <UserActivities user={user} />
        </Tab.Pane>
        <Tab.Pane eventKey={'11'} mountOnEnter={true} unmountOnExit={true}>
          <Subscriptions user={user} />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  )
}
export default UserTabs
