import Chart from 'react-apexcharts'
import Avatar from '@components/avatar'
import { MoreVertical, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'react-feather'
import { Card, CardHeader, CardTitle, CardBody, Media, Badge, Button } from 'reactstrap'
import { Icon } from '@iconify/react'
import { useState } from 'react'
import moment from 'moment'
import Lottie from 'react-lottie'
import animationData from '../.././../../assets/images/fan-finder-animation.json'
const FansCardStats = ({ colors, trackBgColor, type, fans, connected, lastSyncDate }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const fansPerPage = 12
  const fanStat = []

  const getRatingCategory = (rating) => {
    if (rating >= 85) return { text: 'Super', color: '#28C76F' }     // Green
    if (rating >= 70) return { text: 'Regular', color: '#a7cb61' }   // Yellow
    if (rating >= 55) return { text: 'Mixed', color: '#e99051' }     // Orange
    if (rating >= 40) return { text: 'Low', color: '#FF4444' } // Light Red
    return { text: 'Low', color: '#FF4444' }                         // Red
  }


  if (fans?.fans && fans?.fans.length) {
    fans?.fans.forEach(fan => {
      const rating = parseFloat(fan.rating_percentage)
      const ratingInfo = getRatingCategory(rating)
      const lastSync = moment(fans?.last_sync).format('YYYY-MM-DD')
      const createdAt = moment(fan?.created_at).format('YYYY-MM-DD')
      const isNew = lastSync === createdAt

      fanStat.push({
        image: fan.instagram_profile_s3_url,
        username: fan.instagram_username,
        rating,
        badgeColor: ratingInfo.color,
        ratingText: ratingInfo.text,
        isNew
      })
    })

    // Sort fanStat array by rating in descending order
    fanStat.sort((a, b) => b.rating - a.rating)
  }

  const indexOfLastFan = currentPage * fansPerPage
  const indexOfFirstFan = indexOfLastFan - fansPerPage
  const currentFans = fanStat.slice(indexOfFirstFan, indexOfLastFan)
  const totalPages = Math.ceil(fanStat.length / fansPerPage)
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  const renderTasks = () => {
    if (fanStat.length === 0) {
      return (
        <div className="text-center" style={{ marginTop: '50px' }}>
          {fans?.status === 'pending' ? (
            <>
              <Lottie
                options={animationOptions}
                height={200}
                width={200}
              />
              <h4 style={{ marginBottom: '1rem' }}>
                {type === 1
                  ? "Initial Fan Analysis in Progress 🔍"
                  : "Similar Artists Analysis in Progress 🔍"}
              </h4>
              <p style={{ marginBottom: '0.5rem' }}>
                We are currently analyzing your Instagram profile's latest 6 posts and their comments.
              </p>
              <p style={{ marginBottom: '0.5rem' }} className='mb-2'>
                Processing time varies based on your total number of comments.
              </p>
              <p>
                You'll receive an email notification when your fan analysis dashboard is ready.
              </p>
            </>
          ) : (
            <p>
              {type === 1
                ? "No Artist Fans Available"
                : "No Similar Artist Fans Available"}
            </p>
          )}
        </div>
      )
    }

    return (
      <>

        <div className="fan-grid">
          {currentFans.map((fan, index) => (
            <div
              key={index}
              className="fan-card"
              style={{
                border: (fan.isNew) ? `2px solid ${fan.badgeColor}` : 'none'
              }}
            >
              {(fan.isNew) && (
                <span className="new-badge" style={{ backgroundColor: fan.badgeColor }}>
                  NEW
                </span>
              )}
              <a
                href={`https://instagram.com/${fan.username}`}
                target="_blank"
                rel="noopener noreferrer"
                className="fan-link"
              >
                <img
                  src={fan.image}
                  alt={fan.username}
                  className="fan-image"
                />
                <h6 className="fan-username">{fan.username}</h6>
                <Badge
                  pill
                  style={{
                    backgroundColor: fan.badgeColor,
                    paddingLeft: '1.5rem',
                    paddingRight: '1.5rem'
                  }}
                  className="fan-badge"
                >
                  {fan.ratingText}
                </Badge>
              </a>
            </div>
          ))}
        </div>

        {totalPages > 1 && (
          <div className="pagination-controls">
            <div className="pagination-arrow" onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>
              <ChevronsLeft size={18} />
            </div>
            <div className="pagination-arrow" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
              <ChevronLeft size={18} />
            </div>
            <span className="pagination-text">
              {currentPage} of {totalPages}
            </span>
            <div className="pagination-arrow" onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
              <ChevronRight size={18} />
            </div>
            <div className="pagination-arrow" onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages}>
              <ChevronsRight size={18} />
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <Card>
      <CardHeader className="d-flex justify-content-between align-items-center">
        <div className="reveal-animation">
          <div className="d-flex align-items-center mb-2">
            <Icon icon="mdi:message" className="text-primary mr-1" width={24} />
            <div>
              <small>Latest Analysis</small>
              <h4 className="mb-0">
                {type === 1 ? 'Artist Engaged Fans' : 'Similar Artists Engaged Fans'}
              </h4>
            </div>
          </div>
        </div>
        {/* <CardTitle tag='h4'>{type === 1 ? 'Your Fans' : 'Similar Artist Fans'}</CardTitle> */}
        {type === 1 && fans?.last_sync && (
          <span className="text-muted" style={{ fontSize: '0.85rem' }}>
            Last sync {moment(fans?.last_sync).fromNow()}
          </span>
        )}
      </CardHeader>
      <CardBody className={connected === 0 ? 'text-center' : ''}>
        {connected === 0 ? (
          <p style={{ marginTop: '50px' }}>No Linked Account</p>
        ) : (
          renderTasks()
        )}
      </CardBody>

      <style jsx>{`
        .fan-grid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 1rem;
          padding: 1rem;
          width: 100%;
        }

        .fan-card {
          background: rgba(255,255,255,0.05);
          border-radius: 12px;
          padding: 1rem;
          text-align: center;
          transition: all 0.3s ease;
          minHeight: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
        }

        .new-badge {
          position: absolute;
          top: -10px;
          right: -10px;
          color: white;
          padding: 4px 12px;
          border-radius: 8px;
          font-size: 12px;
          font-weight: bold;
          box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        }

        .fan-image {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          object-fit: cover;
          margin-bottom: 1rem;
        }

        .fan-link {
          text-decoration: none;
        }

        .fan-username {
          color: #fff;
          margin: 0.5rem 0;
          font-size: 0.9rem;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .fan-badge {
          padding: 5px 10px;
        }

        @media (max-width: 1200px) {
          .fan-grid {
            grid-template-columns: repeat(3, 1fr);
          }
        }

        @media (max-width: 768px) {
          .fan-grid {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        .pagination-controls {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 1rem auto;
          padding: 0.5rem;
          gap: 0.5rem;
          width: 100%;
        }

        .pagination-arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          cursor: pointer;
          color: #6e6b7b;
          transition: all 0.15s ease;
          background: rgba(255,255,255,0.05);
        }

        .pagination-arrow:hover:not([disabled]) {
          background: rgba(255,255,255,0.1);
          color: #fff;
        }

        .pagination-arrow[disabled] {
          opacity: 0.5;
          cursor: not-allowed;
        }

        .pagination-text {
          font-size: 0.9rem;
          color: #6e6b7b;
          margin: 0 0.5rem;
        }

        .fan-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 5px 15px rgba(0,0,0,0.2);
        }
      `}</style>
    </Card>
  )
}

FansCardStats.defaultProps = {
  lastSyncDate: null
}

export default FansCardStats
